/** @jsxImportSource @emotion/react */
import React, { ChangeEventHandler, Component, KeyboardEvent } from 'react'
import MUIAutocomplete, { AutocompleteProps as MUIAutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from 'utils'
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export type AutocompleteProps<Option, Value> = {
  options?: Option[];
  onChange?: (event: { target: { value: Value } }) => void;
  onSuggestionsFetchRequested?: (text: string, callback: (options: Option[]) => void) => void;
  label?: string;
  value?: Value;
  variant?: 'filled' | 'outlined' | 'standard';
  name?: string;
  helperText?: string;
  error?: boolean;
} & Omit<MUIAutocompleteProps<Value, boolean | undefined, boolean | undefined, boolean | undefined>, "renderInput" | "options" | "onChange">

type State<Option> = {
  loading: boolean;
  open: boolean;
  options: Option[];
}

export class Autocomplete<Option = any, Value = any> extends Component<AutocompleteProps<Option, Value>, State<Option>> {

  state = {
    options: [],
    loading: true,
    open: false
  }

  handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.requestFetch(event.target.value)
  }

  handleReceiveOptions = (options) => {
    if (this.state.options !== options) {
      this.setState({ loading: false })
      this.setState({ options })
    }
  }

  requestFetch = debounce((text) => {
    if (!this.props.onSuggestionsFetchRequested) {
      if (!this.props.options) {
        console.warn("No options given to Autocomplete. Please provide one of the following props: onSuggestionsFetchRequested(text, callback) or options[array]")
      }
    }
    else {
      text = typeof text === 'string' ? text : this.getOptionLabel(this.props.value as Value)
      this.setState({ loading: true })
      this.props.onSuggestionsFetchRequested(text, this.handleReceiveOptions)
    }
  })

  handleChange = (event, value) => {
    this.props.onChange && this.props.onChange({ target: { value } })
  }

  get getOptionLabel() {
    if (this.props.getOptionLabel) {
      return this.props.getOptionLabel;
    }

    return option => typeof option === 'object' ? option.name : option
  }

  render = () => {
    const { onSuggestionsFetchRequested, getOptionLabel, onChange, label, error, helperText, options, variant, fullWidth, ...acProps } = this.props
    return (
      <MUIAutocomplete
        {...acProps}
        onOpen={this.requestFetch}
        onChange={this.handleChange}
        options={this.state.options}
        loading={this.state.loading}
        getOptionLabel={this.getOptionLabel}
        renderInput={params => (
          <TextField {...params}
            onChange={this.props.onSuggestionsFetchRequested ? this.handleTextChange : undefined}
            fullWidth={fullWidth}
            label={label}
            variant={variant}
            error={error}
            helperText={helperText}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const label = this.getOptionLabel(option)
          const matches = match(label, inputValue);
          const parts = parse(label, matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span key={index} css={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
    )
  }

}

export default Autocomplete