/** @jsxImportSource @emotion/react */
import { useFilter, useQueryString, usePlot, useAuthorization, useDependency } from 'hooks'
import React, {useEffect, useState} from 'react'
import { ControlledForm, AutocompleteSearch, DateTimePicker, LabeledSelect, ExportButton } from 'components'
import { TimeSeriesPlot, GroupOptions }  from 'components/charts'
import { StylesObject } from "types"
import { formatPickerDateTime } from 'utils'
import * as API from 'api'
import moment from 'moment'
import Dygraph from 'dygraphs'
import IconButton from '@mui/material/IconButton';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

const SERIES = {
  fir_dispatch: "FIR Dispatch",
  sir_dispatch: "SIR Dispatch",
  fir_invoice_revenue: 'FIR Revenue',
  sir_invoice_revenue: 'SIR Revenue',
  fir_revenue: 'Unadjusted FIR Revenue',
  sir_revenue: 'Unadjusted SIR Revenue',
}

const DEFAULT_INDIVIDUAL_GROUP = null

const DEFAULT_AGGREGATE_GROUP = {
  type: 'aggregate',
  function: 'sum',
  interval: 'trading_period',
}

// settlement runs are performed every month on the 15th, and they settle the previous month
const defaultSettementRange = () => {
  const now = moment()
  const monthsAgo = (now.date() < 15) ? 2 : 1
  const from  = now.subtract(monthsAgo, 'months').startOf('month')
  const to = moment(from).endOf('month')

  return {from: formatPickerDateTime(from), to: formatPickerDateTime(to)}
}

const Settlements = () => {
  
  const [urlState, setUrlState] = useQueryString()
  const [filter, setFilter] = useFilter(setUrlState, 'settlements')
  const [plotState, plotActions] = usePlot("settlements")
  const source = filter.source
  const plot = plotState.plot
  const scope = filter.scope
  const series = filter.series || ''
  const site = filter.site
  const installedMeters = site?.installedMeters || []
  const isRevenue =  series.includes('revenue')
  const isMeterData = source === 'meter_data'
  const authorization = useAuthorization()
  const permittedSeries = authorization.admin || authorization.internal ? SERIES : Object.fromEntries(Object.entries(SERIES).filter(([key]) => !['fir_revenue', 'sir_revenue'].includes(key)))
  const [dygraphOptions, setDygraphOptions] = useState({})

  useEffect(() => {
    // set default filter
    if (Object.keys(filter).length === 0){
      setFilter({
        source: 'meter_data',
        scope: 'aggregate',
        group: DEFAULT_AGGREGATE_GROUP,
        series: 'fir_dispatch',
        ...defaultSettementRange()
      })
    }
  }, [])

  const [loading] = useDependency( async () => {
    const timeSeries = currentTimeSeries()
    if (timeSeries.length > 0) {
      await plotActions.plot({ timeSeries })
    }
  }, [JSON.stringify(filter)])

  const currentIndividualTimeSeries = () => {
    if (isMeterData) {
      // a series per selected site.installed_meters
      return installedMeters.map(im => ({
        series,
        label: `${SERIES[series]} ${im.meterSerial}`,
        filter: {
          source,
          installedMeterId: im.id,
          tradingDatetimeFrom: filter.from,
          tradingDatetimeTo: filter.to,
        },
        group: filter.group
      }))
    } else {
      // only one hot water bulk reserve source/customer
      return [{
        series,
        label: `Hot Water ${SERIES[series]}`,
        filter: {
          source,
          tradingDatetimeFrom: filter.from,
          tradingDatetimeTo: filter.to,
        },
        group: filter.group
      }]
    }
  }

  const currentAggregateTimeSeries = () => {
    return [{
      series,
      label: `Aggregated ${SERIES[series]}`,
      filter: {
        source,
        customerId: filter.customer?.id,
        siteId: filter.site?.id,
        tradingDatetimeFrom: filter.from,
        tradingDatetimeTo: filter.to,
      },
      group: filter.group
    }]
  }

  const currentTimeSeries = () => {
    if (!series){ return [] }
    switch (scope) {
      case 'individual':
        return currentIndividualTimeSeries()
      case 'aggregate':
        return currentAggregateTimeSeries()
      default:
        return []
    }
  }

  const onSuggestionsFetchRequestedSites = async (text, callback) => {
    const sites: any = API['Sites']
    const { data } = await sites.index({
      options: {
        fields: {sites: "id,icpNumber", installedMeters: "id,meterSerial"},
        filter: {search: text, customerId: filter.customer?.id},
        page: { number: 1, size: 15 },
        include: 'customer,installedMeters'
      }
    })
    callback(data)
  }

  const handleSourceChange = ({target: {value}}: any) => {
    const reset = value === 'hot_water' ? {scope: 'individual', group: DEFAULT_INDIVIDUAL_GROUP, customer: null, site: null} : {scope: 'aggregate', group: DEFAULT_AGGREGATE_GROUP}
    setFilter({source: value, ...reset})
    plotActions.reset()
  }

  const handleScopeChange = ({target: {value}}: any) => {
    setFilter({ scope: value, group: value === 'aggregate' ? DEFAULT_AGGREGATE_GROUP : DEFAULT_INDIVIDUAL_GROUP })
    plotActions.reset()
  }

  // keep customer/site relationship valid
  const handleCustomerChange = ({target: {value}}: any) => {
    setFilter({...filter, customer: value, site: value?.id === filter.site?.customer?.id ? filter.site : null})
  }

  const handleSiteChange = ({target: {value}}: any) => {
    setFilter({...filter, site: value, customer: value?.customer || filter.customer})
  }

  const zoomOut = () => {
    setDygraphOptions({ dateWindow: null, valueRange: null, updatedAt: Date.now() })
  }

  const renderFilter = () =>
    <ControlledForm onChange={setFilter} data={filter}>
      <div css={styles.filterRow}>
        <LabeledSelect
          css={styles.leftFilter}
          name="source"
          label="Source"
          fullWidth
          onChange={handleSourceChange}
          options={{meter_data: 'Meter Data', hot_water: 'Hot Water'}}
        />
        <LabeledSelect
          css={styles.filter}
          name="series"
          fullWidth
          options={permittedSeries}
        />
        {isMeterData &&
        <AutocompleteSearch
          css={styles.filter}
          name='customer'
          searchableField='name'
          onChange={handleCustomerChange}
        />}
        {isMeterData &&
        <AutocompleteSearch
          css={styles.filter}
          name='site'
          searchableField='icpNumber'
          onSuggestionsFetchRequested={onSuggestionsFetchRequestedSites}
          onChange={handleSiteChange}
          error={scope === 'individual' && !site}
          helperText={scope === 'individual' && !site ? 'is required' : ''}
        />}
        <DateTimePicker
          css={styles.filter}
          name="from"
        />
        <DateTimePicker
          css={styles.rightFilter}
          name="to"
        />
      </div>
      <div css={styles.filterRow}>
        <div css={styles.groupOptions}>
        {isMeterData &&
          <LabeledSelect
            css={styles.scopeSelect}
            name="scope"
            label="Plot"
            onChange={handleScopeChange}
            options={{individual: 'Per Meter', aggregate: 'Aggregated'}}
          />}
          <GroupOptions scope={scope} name='group'/>
          <ExportButton
            css={styles.exportButton}
            variant='text'
            resource="settlements/plot"
            params={plotState.params}
            disabled={!plot?.values?.length}
            qsOptions={{ arrayFormat: 'brackets'}}
          />
          <IconButton onClick={zoomOut} aria-label="zoom out"><ZoomOutIcon/></IconButton>
        </div>
      </div>
    </ControlledForm>

  return (
    <div css={styles.container}>
      {renderFilter()}

      <TimeSeriesPlot
        plot={plot}
        unit={isRevenue ? '$ ' : 'MW'}
        appendUnit={!isRevenue}
        ylabel={isRevenue ? "Revenue ($)" : "Dispatch (MW)"}
        maxGranularity={Dygraph.Granularity.THIRTY_MINUTELY}
        dygraphOptions={dygraphOptions}
        loading={loading}
      />
    </div>
  )
}

const styles = {
  container: {
    paddingTop: 40,
  },
  filterRow: {
    display: 'flex',
  },
  filter: {
    flex: 1,
    paddingLeft: 3,
    paddingRight: 3,
  },
  leftFilter: {
    flex: 1,
    paddingRight: 3,
  },
  rightFilter: {
    flex: 1,
    paddingLeft: 3,
  },
  scopeSelect: {
    marginRight: 10,
  },
  groupOptions: {
    marginTop: 20,
    width: '100%',
    textAlign: 'right',
  },
  exportButton: {
    marginTop: 16,
    marginLeft: 20,
  }
} as StylesObject

export default Settlements