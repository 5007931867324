/** @jsxImportSource @emotion/react */
import React, { Component, Fragment } from 'react'
import FilteredSearchInput from './FilteredSearchInput'
import { ControlledForm, ConfirmationDialog, LinkButton } from 'components'
import { debounce } from 'utils'

type Filter = Record<string, any>
export type SearchComboProps = {
  className?: string;
  onFilterChange: (filter: Filter) => void;
  dialogTitle?: string;
  searchTextMember: string;
  filter: Filter;
  children?: React.ReactNode;
}

type State = {
  filter: Filter;
  filtersOpen: boolean;
}

export class SearchCombo extends Component<SearchComboProps, State> {
  static defaultProps = {
    className: '',
    dialogTitle: 'Filters',
    searchTextMember: 'freeText'
  }

  constructor(props) {
    super(props)

    this.state = {
      filtersOpen: false,
      filter: props.filter
    }
  }

  get hasFiltersApplied(){
    return Object.values(this.props.filter).some(x => x && (!Array.isArray(x) || x.length !== 0))
  }

  handleChange = debounce(() => {
    this.props.onFilterChange(this.state.filter)
  })

  handleOpenFilters = () => {
    this.setState({
      filtersOpen: true,
      filter: this.props.filter
    })
  }

  handleCloseFilters = () => {
    this.setState({filtersOpen: false})
  }

  handleFilterConfirm = () => {
    this.handleChange()
    this.handleCloseFilters()
  }

  handleFilterCancel = () => {
    this.setState({filter: this.props.filter})
    this.handleCloseFilters()
  }

  handleTextChange = ({target: { value: filterText }}) => {
    this.setState({
      filter: {...this.state.filter, [this.props.searchTextMember]: filterText }
    }, this.handleChange)
  }

  handleFilterChanged = (filter) => {
    this.setState({filter})
  }

  handleResetFilter = () => {
    this.setState({
      filter: Object.entries(this.state.filter).reduce((agg, [key, val]) => ({...agg, [key]: Array.isArray(val) ? [] : undefined}), {})
    })
  }

  handleClearFilter = () => {
    this.setState({
      filter: Object.entries(this.state.filter).reduce((agg, [key, val]) => ({...agg, [key]: Array.isArray(val) ? [] : undefined}), {})
    }, () => this.handleFilterConfirm())
  }

  render = () => {
    const { dialogTitle, children, searchTextMember, onFilterChange, ...textFieldProps } = this.props
    const { filter, filtersOpen } = this.state
    return (
      <Fragment>
        <FilteredSearchInput
          {...textFieldProps}
          onOpenFilters={this.handleOpenFilters}
          onChange={this.handleTextChange}
          hasFiltersApplied={this.hasFiltersApplied}
          onClearFilter={this.handleClearFilter}
          value={filter[searchTextMember] || ''}
        />
        <ConfirmationDialog
          title={dialogTitle}
          open={filtersOpen}
          onConfirm={this.handleFilterConfirm}
          onCancel={this.handleFilterCancel}
          confirmLabel={'Filter'}
          cancelLabel={'Cancel'}
        >
          <ControlledForm onChange={this.handleFilterChanged} data={filter}>
            <div css={styles.form}>
              {children}
            </div>
            <LinkButton onClick={this.handleResetFilter}>Reset Filters</LinkButton>
          </ControlledForm>
        </ConfirmationDialog>

      </Fragment>
    )
  }
}

const styles = {
  form: {
    paddingBottom: 20,
    '& h6': {
      marginTop: 20
    }
  }
}

export default SearchCombo
